/*
 * @Author: 智客云网络科技
 * @Date: 2021-11-01 15:00:52
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-04 01:11:58
 * @Description: 页面专属接口管理
 * @FilePath: \src\views\App\Package\api.js
 */

import http from "@/api";

/**************************** 包裹操作 ****************************/

// 创建新包裹
export const createPackage = (params) =>
  http.post("/v1/Package/CreatePackage", params);

// 删除包裹
export const delPackage = (params) =>
  http.delete("/v1/Package/DelPackage", params);

// 修改包裹信息
export const updatePackage = (params) =>
  http.put("/v1/Package/UpdatePackage", params);

// 根据ID查询包裹信息
export const getPackageInfo = (params) =>
  http.get("/v1/Package/GetPackageInfo", params);

// 将包裹导入可发货列表
export const readyDelivery = (params) =>
  http.post("/v1/Package/ReadyDelivery", params);

// 撤回可发货包裹
export const unReadyDelivery = (params) =>
  http.post("/v1/Package/UnReadyDelivery", params);

// 导出申报单
export const getDeclareSheet = (params) =>
  http.get("/v1/CustomsDeclaration/GetDeclareSheet", params);

// 获取待申报包裹表格
export const getUnDeclaredPackageTable = (params) =>
  http.get("/v1/Package/GetUnDeclaredPackageTable", params);

// 获取可发货包裹表格
export const getDeliverablePackageManage = (params) =>
  http.get("/v1/Package/GetDeliverablePackageManage", params);

/**************************** 渠道操作 ****************************/

//获取渠道列表
export const getChannelList = (params) =>
  http.get("/v1/Channel/GetChannelList", params);
